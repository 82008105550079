import './App.css';

import logo from './logo.svg'

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img className="App-logo" src={logo} alt="nuts logo"/>
            </header>
            <footer className="App-footer">
                <div>
                    <p className="App-text-soon">
                    soon to be
                    </p>
                    <p className="App-text-nuts">
                    nuts about language
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default App;
